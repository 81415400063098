import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/Layout'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import VypePath from 'components/VypePath'
import { H2 } from 'styles/typography'
import { white } from 'styles/colors'

export default () => {
  return (
    <Layout>
      <div style={{ backgroundImage: 'linear-gradient(88.21deg, #212126 0%, #37374E 100%)' }}>
        <Section>
          <Wrapper narrow>
            <SectionBlock className='u-text-center'>
              <H2 style={{ 'color': white }}>You are one step away from <br className='u-hide-small-down' />your new device</H2>
            </SectionBlock>
            <SectionBlock>
              <VypePath
                icon='exit'
                heading='I already have a glo account'
                link='https://govype.ca'
                steps={[
                  'Log onto govype.ca with your glo credentials',
                  'In the account section under “account details”, sign-up to Vype newsletter',
                  'You will receive an email with a promo code you can use to redeem your ePod Starter kit'
                ]}
              />
              <VypePath
                icon='account'
                heading='I do not have a glo account'
                link='https://join.govype.ca'
                steps={[
                  'Click on “go to Vype” under this section',
                  'Follow the steps on the page to claim your ePod Starter kit',
                ]}
              />
            </SectionBlock>
            <SectionBlock className='u-text-center'>
              <p><Link to='/contact-us'>Any questions? Get in touch</Link></p>
            </SectionBlock>
          </Wrapper>
        </Section>
      </div>
    </Layout>
  )
}
