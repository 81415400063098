import React from 'react'
import { string, object } from 'prop-types'
import styled from 'styled-components'
import { rgba, stripUnit } from 'polished'

import * as spacing from 'styles/spacing'
import { media, mediaDown } from 'styles/media'
import { borderRadius, square } from 'styles/global'
import { H3, fontSizes, fontWeights } from 'styles/typography'
import { black, white, greyLight } from 'styles/colors'
import Icon from 'components/Icon'
import Button from 'components/button/Button'

const Container = styled.div`
  width: 100%;
  background-color: ${rgba(black, 0.35)};
  padding: ${spacing.large};
  border-radius: ${stripUnit(borderRadius) * 2 + 'px'};

  ${mediaDown.medium`
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  `};

  ${media.small`
    display: flex;
  `};

  & + & {
    margin-top: ${spacing.small};

    ${media.medium`
      margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'}
    `};
  }

  ${media.large`
    padding: ${spacing.xLarge};
  `};
`

const Heading = styled(H3)`
  color: ${white};
  margin-bottom: ${spacing.medium};

  ${media.small`
    margin-top: ${spacing.small};
  `};

  ${media.medium`
    margin-bottom: ${spacing.large};
  `};
`

const Content = styled.div`
  margin-bottom: ${spacing.large};

  ${media.medium`
    margin-bottom: ${spacing.xLarge};
    padding-right: 10%;
  `};
`

const Step = styled.span`
  display: flex;
  color: ${greyLight};

  & + & {
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'}
  }
`

const StepNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(white, 0.1)};
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.medium};
  color: ${white};
  ${square('24px')};
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: ${stripUnit(spacing.small) * 1.5 + 'px'};

  ${media.medium`
    margin-right: ${spacing.medium};
  `};
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${square('48px')};
  color: ${white};
  background-color: ${rgba(black, 0.3)};
  border-radius: 50%;
  margin-bottom: ${spacing.medium};

  ${media.small`
    margin-bottom: 0;
    margin-right: ${spacing.large};
  `};
`

const Main = styled.main`
  flex: 1;
`

const VypePath = ({ icon, heading, link, steps }) => (
  <Container>
    <IconWrap>
      <Icon icon={icon} />
    </IconWrap>
    <Main>
      <Heading>{heading}</Heading>
      <Content>
        {steps.map((step, i) => (
          <Step key={i}>
            <StepNumber>{i + 1}</StepNumber>
            <span>{step}</span>
          </Step>
        ))}
      </Content>
      <Button
        theme='primary'
        href={link}
      >
        Go to Vype
      </Button>
    </Main>
  </Container>
)

VypePath.propTypes = {
  icon: string.isRequired,
  heading: string.isRequired,
  link: string.isRequired,
  steps: object.isRequired
}

export default VypePath
